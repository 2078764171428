<template lang="html">
    <div class="Row-Wrap" :class="[{ 'is-empty': mTrips.length === 0 }]">
        <Row
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            @onClickColumn="e => handleHeaderClick(e)"
            class="Row-Header"
        />
        <Row
            isDropdown
            v-for="(trip, i) in mTrips"
            :parentHover="true"
            :items="trip"
            :key="i"
            :index="i"
        >
        </Row>
        <EmptyState v-if="mTrips.length === 0" />
        <Button
            v-if="mTrips.length >= 30 && mTrips.length < trips.length"
            @onClick="handleLoadMore"
        >
            Mehr Laden
        </Button>
    </div>
</template>

<script>
import { format } from 'date-fns';
import Row from '@/components/Row';
import Button from '@/components/widgets/Button';
import { round2d } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'TripsTable',
    components: {
        Row,
        Button,
        EmptyState,
    },
    props: {
        trips: {
            type: Array,
        },
    },
    data: () => {
        return {
            drivers: JSON.parse(localStorage.getItem('drivers')),
            cursor: 80,
            sortState: {
                column: 'Abfahrt',
                ascending: false,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Fahrer', value: '' },
                { name: 'Kennzeichen', value: '' },
                { name: 'Schicht Nr', value: '' },
                { name: 'Signal Nr', value: '' },
                { name: 'Abfahrt', value: '' },
                { name: 'Ankunft', value: '' },
                { name: 'Gesamt KM', value: '' },
                { name: 'Besetzt KM', value: '' },
                { name: 'Leer KM', value: '' },
                { name: 'Umsatz (€)', value: '' },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mTrips() {
            return this.trips.length > 0
                ? this.sortTrips([...this.formatTrips(this.trips.slice(0, this.cursor))])
                : [];
        },
    },
    methods: {
        sortTrips(trips) {
            const { column, ascending } = this.sortState;
            const sorted = trips.sort((a, b) => {
                const aEntry = a.row.find(item => {
                    return item.name == column;
                });
                const bEntry = b.row.find(item => {
                    return item.name == column;
                });
                const aVal = aEntry?.rawValue || aEntry?.value;
                const bVal = bEntry?.rawValue || bEntry?.value;

                if (aVal < bVal) {
                    return ascending ? -1 : 1;
                }
                if (aVal > bVal) {
                    return ascending ? 1 : -1;
                }
                return 0;
            });
            return sorted;
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        handleDriverNameClick({ uuid }) {
            this.$emit('onDriverClick', uuid);
        },
        hasWarning(entry) {
            return entry.data?.subtractedTotalAmount !== 0 || !entry.data.driver?.name;
        },
        hasError(entry) {
            const driver = this.drivers.find(d => d.id === entry.driverNumber);
            const driverNumberIsName = entry.driver?.name == entry.driver.driverNumber;
            // const hiddenDriverHasRevenue = entry.finalTotalAmount > 0 && !driver?.isVisible;
            return driverNumberIsName;
        },
        hasTripWarning(entry) {
            return entry?.subtractedTotalAmount !== 0;
        },
        handleLoadMore() {
            this.cursor += 80;
        },
        formatTrips(shifts) {
            const s = shifts.map(trip => {
                const driver = this.drivers.find(d => d.id === trip.driverNumber);

                return {
                    row: [
                        {
                            name: 'Fahrer',
                            value: driver?.value || trip.driverNumber,
                            rawValue: trip.driver?.name,
                        },
                        {
                            name: 'Kennzeichen',
                            value: trip?.licenseNumber || '',
                            rawValue: trip?.licenseNumber,
                        },
                        { name: 'Schicht Nr', value: trip.shiftSequenceNumber },
                        { name: 'Signal Nr', value: trip.sequenceNumber },
                        {
                            name: 'Abfahrt',
                            value: format(new Date(trip.startAt), 'dd.MM.yyyy HH:mm'),
                            rawValue: new Date(trip.startAt),
                        },
                        {
                            name: 'Ankunft',
                            value: format(new Date(trip.endAt), 'dd.MM.yyyy HH:mm'),
                            rawValue: new Date(trip.endAt),
                        },
                        {
                            name: 'Gesamt KM',
                            value: round2d(trip.totalDistance / 1000).format(),
                            rawValue: trip.totalDistance,
                        },
                        {
                            name: 'Besetzt KM',
                            value: round2d(trip.hiredDistance / 1000).format(),
                            rawValue: trip.hiredDistance,
                        },
                        {
                            name: 'Leer KM',
                            value: round2d(trip.onCallDistance / 1000).format(),
                            rawValue: trip.onCallDistance,
                        },
                        {
                            name: 'Umsatz (€)',
                            value: `<span style="color:${
                                this.hasTripWarning(trip) ? 'red' : 'inherit'
                            }">${round2d(trip.finalTotalAmount / 100).format()}</span>`,
                            rawValue: trip.finalTotalAmount,
                        },
                    ],
                    data: trip,
                };
            });
            return s;
        },
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    > * {
        min-width: 1000px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .Button {
        display: block;
        margin: 0 auto !important;
    }
}
</style>
